.card {
    background-color: var(--base-white-100);
    border-radius: var(--space8);
    padding: var(--space16);
    margin-bottom: var(--space16);
    box-shadow: 0 8px 12px #4848F215;
    list-style: none;
    border: 1px solid #00000020
}

.hover-card {
    cursor: pointer;
}

.hover-card:hover {
    background-color: #fafafa;
}

.page-body-a {
    display: flex;
    flex-direction: row;
    flex: 1;
    /*background-color: var(--off-white);*/
}

.page-body-a-left {
    /*padding: 24px 20px;*/
    /*margin: 0 -20px;*/
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    box-sizing: border-box;
}

.hide-scrollbar {
    overflow-y: auto; /* Enable scrolling */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent; /* For Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0; /* Adjust the width as needed */
}

.hide-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* Transparent background */
}

.hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent; /* Transparent thumb */
}

.hide-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Hover color (optional) */
}

.text-input-wrapper {
    position: relative;
}

.show-scrollbar {
    overflow-y: auto; /* Enable scrolling */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: black black; /* For Firefox */
}

input {
    background-color: var(--base-white-4) !important;
    border: 1px solid var(--base-white-10) !important;
    outline: none !important;
    border-radius: 12px;
    height: 40px;
    color: var(--base-white-100) !important;
}

input:disabled {
    cursor: not-allowed !important;
    background-color: #00000010 !important;
}

input::placeholder {
    color: var(--base-white-50) !important
}

input:focus {
    border: 1px solid var(--primary-tint) !important;
}

.input-light {
    /*background-color: var(--base-black-4) !important;*/
    border: 1px solid #00000023 !important;
    outline: none !important;
    border-radius: 8px;
    height: 40px;
    color: var(--base-black-100) !important;
}

.input-light::placeholder {
    color: var(--base-black-50) !important
}

.input-light:focus {
    border: 1px solid var(--primary-tint) !important;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
}

.input-wrapper {
    padding: 14px 15px !important;
    border: 1px solid #e8e8e8 !important;
    border-radius: 12px !important;
    font-size: 16px !important;
    color: #333 !important;
    background-color: #f9f9f9 !important;
    height: 48px!important;
}

.input-wrapper::placeholder {
    color: #a4a4a4 !important;
}

.input-wrapper:focus {
    border-color: #6200ea !important;
    background-color: #fff !important;
    outline: none !important;
}

.input-wrapper input:valid {
    border-color: #4caf50 !important;
}

.input-wrapper:invalid {
    border-color: #f44336 !important;
}

.input-wrapper input.error {
    border-color: #f44336 !important;
    background-color: #ffe6e6 !important;
}

.error-message {
    color: #f44336;
    font-size: 12px;
    margin-top: 5px;
}

.pill {
    /*background-color: var(--base-white-10);*/
    border: 1px solid #FFFFFF24 !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-white-50)
}

.pill:hover {
    background-color: var(--base-white-4);
}

.pill-light {
    /*background-color: var(--base-white-10);*/
    border: 1px solid #00000023 !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-black-50)
}

.pill-light:hover {
    background-color: var(--base-black-4);
}

.pill-inactive {
    /*background-color: var(--base-white-10);*/
    border: 1px solid var(--base-black-10) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-black-30)
}

.pill-inactive:hover {
    background-color: var(--base-white-4);
}

.pill-active {
    background-color: var(--primary-tint);
    border: 1px solid var(--primary-tint);
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-white-100)
}

.pill-active:hover {
    background-color: var(--primary-dark);
}

.pill-light-inactive {
    /*background-color: var(--base-white-10);*/
    border: 1px solid var(--base-black-20) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-black-50)
}

.pill-light-inactive:hover {
    background-color: var(--base-black-4);
}

.pill-light-active {
    background-color: var(--primary-tint);
    border: 1px solid var(--primary-tint) !important;
    outline: none !important;
    border-radius: 100px;
    height: 40px;
    padding: 0 8px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--base-white-100)
}

.pill-light-active:hover {
    background-color: var(--primary-dark);
}


.dropdown-new {
    position: relative;
}

.dropdown-menu {
    background-color: var(--blacks-dropdown) !important;
    width: max-content !important;
    box-shadow: 0 2px 20px var(--base-black-50) !important;
    border-radius: var(--space8) !important;
    box-sizing: border-box;
    /*max-height:80vh;*/
    /*overflow-y: scroll;*/
}

.dropdown-menu-light {
    background-color: var(--base-white-100) !important;
    width: max-content !important;
    box-shadow: 0 2px 20px var(--primary-light) !important;
    border-radius: var(--space8) !important;
    box-sizing: border-box;
    border: 1px solid #00000020
}

.popover-style {
    background-color: var(--base-white-100) !important;
    box-shadow: 0 2px 20px var(--primary-light) !important;
    border-radius: var(--space8) !important;
    box-sizing: border-box;
    border: 1px solid #00000020
}

.dropdown-menu-option {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    transition: 150ms ease !important;
    padding: var(--space8) var(--space16) !important;
    cursor: pointer !important;
    color: var(--base-white-100) !important;
    min-width: 150px;
    /*body1-medium*/
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
}

.dropdown-menu-option-light {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    transition: 150ms ease !important;
    padding: var(--space8) var(--space16) !important;
    cursor: pointer !important;
    color: var(--base-black-70) !important;
    min-width: 150px;
    /*body1-medium*/
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
}

.dropdown-menu-option:first-child, .dropdown-menu-option-light:first-child {
    border-radius: var(--space12) var(--space12) 0 0;
}

.dropdown-menu-option:last-child, .dropdown-menu-option-light:last-child {
    border-radius: 0 0 var(--space12) var(--space12);
}

.dropdown-menu-option:hover {
    background-color: var(--base-white-10);
}

.dropdown-menu-option.active {
    background-color: var(--base-white-30);
}

.dropdown-menu-option-light:hover {
    background-color: var(--base-black-4);
}

.dropdown-menu-option-light.active {
    background-color: var(--primary-tint);
}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 2px solid var(--base-white-30)
}

.checkbox-active {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 2px solid var(--primary-tint);
    background-color: var(--primary-tint);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon-badge-lg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--primary-light);
    width: 48px;
    height: 48px;

}

.separator-h {
    height: 1px;
    width: 100%;
    background-color: var(--base-black-8);
}

.text-ellipsis-area {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6; /* Adjust the number of lines before ellipsis */
    overflow: hidden;
    text-overflow: ellipsis;
}

.brand-gradient-light {
    background: linear-gradient(59deg, var(--primary-tint) 0%, rgba(39, 38, 102, 0.90) 100%);
}


.brand-gradient-dark {
    background: linear-gradient(59deg, #1F2124 0%, rgba(39, 38, 102, 0.90) 100%);
}

.brand-gradient-dark-2 {
    background: linear-gradient(-59deg, #1F2124 0%, rgba(39, 38, 102, 0.90) 100%);
}

.recruiter-gradient {
    background: linear-gradient(180deg, #3538CDF0 20%, #222493 100%);
}

.recruiter-gradient-rev {
    background: linear-gradient(-200deg, #3538CDF0 20%, #222493 100%);
}


.content-divider-vertical {
    width: 1px;
}

.stepper-list-dot {
    width: 20px;
    height: 20px;
    border-radius: var(--space24);
    background-color: var(--base-black-10);
    margin: 1px 2px 2px 2px;
}

.spinner {
    height: 1em;
    width: 1em;
    border: 1px solid var(--primary-tint);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: var(--primary-light);
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

.spinner-light {
    height: 1em;
    width: 1em;
    border: 1px solid var(--base-white-100);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: var(--base-white-30);
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

@keyframes rotate {
    100% {
        transform: rotate(-360deg);
    }
}

.fade-in-on-scroll {
    opacity: 0;
    transition: all 150ms ease-in-out;
    transform: translateY(40px);
}

.fade-in-on-scroll.visible {
    opacity: 1;
    transform: translateY(0px);
}

.togglebutton {
    background-color: transparent;
    border: none;
    border-radius: 20px;
    padding: 0;
}

.togglebutton:focus {
    box-shadow: 0 0 6px var(--primary-tint) !important;
}

.togglebutton-innercontainer {
    border: none;
    border-radius: 20px;
    width: 51px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.togglebutton-circle {
    position: absolute;
    border-radius: 20px;
    width: 26px;
    height: 26px;
    background-color: #FFFFFF;
    transition: 200ms ease;
}

.DialogContent {
    overflow-y: scroll !important;
}

.shimmer {
    animation: shimmer 3s infinite linear;
    background: linear-gradient(to right, #00000003, #00000007, #00000003);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        background-position: 1000px 0;
    }
}


.Toastify__toast-container--bottom-right {
    bottom: 0px !important;
    right: 62px !important;
}

.Toastify__toast {
    border-radius: 8px !important;
}

.Toastify__toast-body {
    font-weight: 600 !important;
    font-family: 'Manrope', sans-serif !important;
    font-size: 14px !important;
}

.checklist-button {
    padding: var(--space20) var(--space16) !important;
    transition: var(--transition1);
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    border-radius: var(--space8);
    text-align: left;
    overflow: hidden;
}

.checklist-button:hover:enabled {
    /*background-color: transparent;*/
    box-shadow: none;
}

.checklist-button:active:enabled {
    color: var(--base-white-100) !important;
}

.company-collapse__button {
    color: var(--primary-tint);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 100px;
    transition: 150ms !important;
}

.company-collapse:hover .company-collapse__button {
    color: var(--base-white-100);
    background-color: var(--primary-tint);
    outline: 1px solid var(--primary-light);
    outline-offset: 2px;
}

.sort {
    cursor: pointer;
    border-bottom: 2px solid #00000005 !important;
    line-height: 20px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.sort-sorting {
    cursor: pointer;
    border-bottom: 2px solid var(--primary-tint) !important;
    line-height: 20px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: var(--primary-tint) !important;
}

.sort-arrow-up, .sort-arrow-down {
    display: none;
}

.sort.asc .sort-arrow-up {
    display: flex;
}

.sort.desc .sort-arrow-down {
    display: flex;
}
