
button {
    outline: none !important;
    stroke: none !important;
    border-width: 0 !important;
}

.primary-button {
    background-color: var(--primary-tint) !important;
    color: var(--base-white-100) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 0 32px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease-in-out;
    box-shadow: 0 8px 20px #00000010;
    border-radius: 12px;
}

.primary-button:hover {
    transform: translateY(-1px);
    background-color: #2f2fbd !important;
}

.primary-button-pill {
    background-color: var(--primary-light);
    color: var(--primary-tint);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease-in-out;
    box-shadow: 0 8px 20px #00000010;
    border-radius: 100px;
}

.primary-button-pill:hover {
    /*transform: translateY(-1px);*/
    background-color: var(--primary-light-dark);
    color:var(--primary-dark)
}

.primary-button-sm {
    background-color: var(--primary-tint);
    color: var(--base-white-100);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 8px 16px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 8px;
    transition: 300ms ease-in-out;
    box-shadow: 0 8px 20px #00000010;
}

.primary-button-sm:hover {
    box-shadow: 0 2px 12px #00000030;
    background-color: var(--primary-dark);
}

.secondary-button {
    background: var(--base-black-4);
    color: var(--primary-tint);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 0 32px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease;
    border-radius: 12px;
}

.secondary-button:hover {
    transform: translateY(-1px);
    background-color: var(--base-black-8);
}


.secondary-pill-sm {
    background: var(--base-black-4);
    color: var(--base-black-50);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 12px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease;
    border-radius: 12px;
    font-size: 14px ;
    font-weight: bold;
}

.secondary-pill-sm:hover {
    transform: translateY(-1px);
    color: var(--base-black-70);
    background-color: var(--base-black-8);
}


@media screen and (max-width: 1000px) {
     .secondary-button, .primary-button {
        padding: 0 18px;
        height: 36px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1199px) {
     .secondary-button, .primary-button {
        padding: 0 24px;
        height: 48px;
    }
}

@media screen and (min-width: 1200px) {
     .secondary-button, .primary-button {
        padding: 0 32px;
        height: 54px;
    }
}

/*button {*/
/*	 position: relative;*/
/*	 display: inline-block;*/
/*	 cursor: pointer;*/
/*	 outline: none;*/
/*	 border: 0;*/
/*	 vertical-align: middle;*/
/*	 text-decoration: none;*/
/*	 background: transparent;*/
/*	 padding: 0;*/
/*	 font-size: inherit;*/
/*	 font-family: inherit;*/
/*}*/
/* button.learn-more {*/
/*	 width: 12rem;*/
/*	 height: auto;*/
/*}*/
/* button.learn-more .circle {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: relative;*/
/*	 display: block;*/
/*	 margin: 0;*/
/*	 width: 3rem;*/
/*	 height: 3rem;*/
/*	 background: var(--primary-tint);*/
/*	 border-radius: 1.625rem;*/
/*}*/
/* button.learn-more .circle .icon {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: absolute;*/
/*	 top: 0;*/
/*	 bottom: 0;*/
/*	 margin: auto;*/
/*	 background: #fff;*/
/*}*/
/* button.learn-more .circle .icon.arrow {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 left: 0.625rem;*/
/*	 width: 1.125rem;*/
/*	 height: 0.125rem;*/
/*	 background: none;*/
/*}*/
/* button.learn-more .circle .icon.arrow::before {*/
/*	 position: absolute;*/
/*	 content: '';*/
/*	 top: -0.25rem;*/
/*	 right: 0.0625rem;*/
/*	 width: 0.625rem;*/
/*	 height: 0.625rem;*/
/*	 border-top: 0.125rem solid #fff;*/
/*	 border-right: 0.125rem solid #fff;*/
/*	 transform: rotate(45deg);*/
/*}*/
/* button.learn-more .button-text {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: absolute;*/
/*	 top: 0;*/
/*	 left: 0;*/
/*	 right: 0;*/
/*	 bottom: 0;*/
/*	 padding: 0.75rem 0;*/
/*	 margin: 0 0 0 1.85rem;*/
/*	 color: var(--primary-tint);*/
/*	 font-weight: 700;*/
/*	 line-height: 1.6;*/
/*	 text-align: center;*/
/*	 text-transform: uppercase;*/
/*}*/
/* button:hover .circle {*/
/*	 width: 100%;*/
/*}*/
/* button:hover .circle .icon.arrow {*/
/*	 background: #fff;*/
/*	 transform: translate(1rem, 0);*/
/*}*/
/* button:hover .button-text {*/
/*	 color: #fff;*/
/*}*/
/* @supports (display: grid) {*/
/*	 body {*/
/*		 display: grid;*/
/*		 grid-template-columns: repeat(4, 1fr);*/
/*		 grid-gap: 0.625rem;*/
/*		 grid-template-areas: ". main main ." ". main main .";*/
/*	}*/
/*	 #container {*/
/*		 grid-area: main;*/
/*		 align-self: center;*/
/*		 justify-self: center;*/
/*	}*/
/*}*/
