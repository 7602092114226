/* reset */
/*button,*/
/*fieldset,*/
/*input {*/
/*    all: unset;*/
/*}*/
@import '@radix-ui/colors/gray.css';
@import '@radix-ui/colors/blue.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/red.css';
@import '@radix-ui/colors/gray-dark.css';
@import '@radix-ui/colors/blue-dark.css';
@import '@radix-ui/colors/green-dark.css';
@import '@radix-ui/colors/red-dark.css';

.DialogOverlay {
    background-color: var(--base-white-80);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw !important;
    max-width: 650px;
    max-height: 85vh;
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent:focus {
    outline: none;
}

.DialogTitle {
    margin: 0;
    font-weight: 500;
    color: var(--base-black-100);
    font-size: 17px;
}

.DialogDescription {
    margin: 10px 0 20px;
    color: var(--base-black-50);
    font-size: 15px;
    line-height: 1.5;
}

.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
}

.Button.violet {
    background-color: white;
    color: var(--primary-dark);
    box-shadow: 0 2px 10px var(--base-black-50);
}

.Button.violet:hover {
    background-color: var(--base-black-100);
}

.Button.violet:focus {
    box-shadow: 0 0 0 2px black;
}

.Button.green {
    background-color: var(--green-4);
    color: var(--green-11);
}

.Button.green:hover {
    background-color: var(--green-5);
}

.Button.green:focus {
    box-shadow: 0 0 0 2px var(--green-7);
}

.IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--violet-11);
    position: absolute;
    top: 10px;
    right: 10px;
}

.IconButton:hover {
    background-color: var(--violet-4);
}

.IconButton:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
}

/*.Fieldset {*/
/*    display: flex;*/
/*    gap: 20px;*/
/*    align-items: center;*/
/*    margin-bottom: 15px;*/
/*}*/

/*.Label {*/
/*    font-size: 15px;*/
/*    color: var(--violet-11);*/
/*    width: 90px;*/
/*    text-align: right;*/
/*}*/

/*.Input {*/
/*    width: 100%;*/
/*    flex: 1;*/
/*    display: inline-flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    border-radius: 4px;*/
/*    padding: 0 10px;*/
/*    font-size: 15px;*/
/*    line-height: 1;*/
/*    color: var(--violet-11);*/
/*    box-shadow: 0 0 0 1px var(--violet-7);*/
/*    height: 35px;*/
/*}*/

.Input:focus {
    box-shadow: 0 0 0 2px var(--violet-8);
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.SelectTrigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1;
    height: 35px;
    gap: 5px;
    background-color: var(--base-black-4);
    color: var(--primary-dark);
    box-shadow: 0 2px 10px var(--black-a7);
}
.SelectTrigger:hover {
    background-color: var(--mauve-3);
}
.SelectTrigger:focus {
    box-shadow: 0 0 0 2px black;
}
.SelectTrigger[data-placeholder] {
    color: var(--violet-9);
}

.SelectIcon {
    color: Var(--violet-11);
}

.SelectContent {
    overflow: hidden;
    background-color: red;
    border-radius: 6px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
    padding: 5px;
}

.SelectItem {
    font-size: 13px;
    line-height: 1;
    color: var(--primary-dark);
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
}
.SelectItem[data-disabled] {
    color: var(--mauve-8);
    pointer-events: none;
}
.SelectItem[data-highlighted] {
    outline: none;
    background-color: var(--violet-9);
    color: var(--violet-1);
}

.SelectLabel {
    padding: 0 25px;
    font-size: 12px;
    line-height: 25px;
    color: var(--mauve-11);
}

.SelectSeparator {
    height: 1px;
    background-color: var(--violet-6);
    margin: 5px;
}

.SelectItemIndicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.SelectScrollButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    color: var(--violet-11);
    cursor: default;
}

.ToastViewport {
    --viewport-padding: 25px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: var(--viewport-padding);
    gap: 10px;
    width: 390px;
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: 2147483647;
    outline: none;
}

.ToastRoot {
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    padding: 15px;
    display: grid;
    grid-template-areas: 'title action' 'description action';
    grid-template-columns: auto max-content;
    column-gap: 15px;
    align-items: center;
}
.ToastRoot[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state='closed'] {
    animation: hide 100ms ease-in;
}
/*.ToastRoot[data-swipe='move'] {*/
/*    transform: translateX(var(--radix-toast-swipe-move-x));*/
/*}*/
.ToastRoot[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe='end'] {
    animation: swipeOut 100ms ease-out;
}

@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(calc(100% + var(--viewport-padding)));
    }
    to {
        transform: translateX(0);
    }
}

@keyframes swipeOut {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(calc(100% + var(--viewport-padding)));
    }
}

.ToastTitle {
    grid-area: title;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--slate-12);
    font-size: 15px;
}

.ToastDescription {
    grid-area: description;
    margin: 0;
    color: var(--slate-11);
    font-size: 13px;
    line-height: 1.3;
}

.ToastAction {
    grid-area: action;
}

.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: 500;
}
.Button.small {
    font-size: 12px;
    padding: 0 10px;
    line-height: 25px;
    height: 25px;
}
.Button.large {
    font-size: 15px;
    padding: 0 15px;
    line-height: 35px;
    height: 35px;
}
.Button.violet {
    background-color: white;
    color: var(--violet-11);
    box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
    background-color: var(--mauve-3);
}
.Button.violet:focus {
    box-shadow: 0 0 0 2px black;
}
.Button.green {
    background-color: var(--green-2);
    color: var(--green-11);
    box-shadow: inset 0 0 0 1px var(--green-7);
}
.Button.green:hover {
    box-shadow: inset 0 0 0 1px var(--green-8);
}
.Button.green:focus {
    box-shadow: 0 0 0 2px var(--green-8);
}


