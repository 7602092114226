.bg-brand {
    background-image: url('./img/brand/bg.png');
    background-size: cover;
}

.recruiter-gradient {
    background-image: linear-gradient(12deg, #0A0A1C, #4859D4);
    background-size: cover;
}

.student-gradient {
    background-image: linear-gradient(12deg, #1E1E51, #2D2F9E, #5A7CC3, #ECB8AA);
    background-size: cover;
}

.jotw-header .bg-white {
    opacity: 0;
}

.jotw-header:hover .bg-white {
    opacity: 1;
}

.react-time-picker__wrapper {
    border: none !important;
    color: black
}

.react-time-picker__inputGroup__input {
    color: black !important;
}

span.react-time-picker__inputGroup__divider {
    margin-right: 2px !important;
}

.react-time-picker__inputGroup__input {
    border-radius: 4px !important;
}



.fade-slide-enter {
    opacity: 0;
    transform: translateY(20px);
}
.fade-slide-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}
.fade-slide-exit {
    opacity: 1;
    transform: translateY(0);
}
.fade-slide-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 500ms, transform 500ms;
}



